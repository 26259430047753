import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';





const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <main className="page--demo demo--trianglefusion">
      <Helmet>
        <title>{data.site.siteMetadata.demoSolarCSSystemTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--main">
            <Header siteTitle="Rob DiMarzo" /> 

            <div className="slab__inner">
                <div className="pageblock__content">   
                  <div className="pageblock__title">
                      <h1>{data.site.siteMetadata.demoSolarCSSystemTitle} </h1>
                  </div>  
                    <p className="text--bold">An educational demo that visualizes each planet's rotational period and orbital tilt using only CSS.</p> 
                    <p>
                        I set out to recreate our solar system's planets as photorealistically as possible with CSS only - without JavaScript or additional libraries. I used a single SCSS mixin that interpolates custom variables to style and animate each planet with their unique properties, in addition to a few shared classes. </p>

                    <h3 className="text--bold">The Math</h3>
                    <p>
                        The planet rotations in this demo are all moving at 36,000x their normal speed. The rotation speed takes 10% of each planet's day length and measures that value in seconds instead of hours.</p>

                    <p>In the SCSS mixin, this happens as: </p>
                    <p>animation: planetRotate calc(var(--$planet-day)*.1s) linear infinite;</p>
                    

                    <p>The formula is:</p>
                    <p>planetDayLengthHours x .1 = planetRotationSpeed(seconds)</p>
                
                    <p>            
                    For example:
                    </p>
                    <ul>
                        <li>Earth: 23.9 x .1 = 2.39 seconds</li>
                        <li>Jupiter: 9.9 x .1 = .99 seconds</li>
                        <li>Venus: 5832.5 x .1 = 583.25 seconds</li>
                    </ul>
                    <p>
                    To get 36,000, I calculate the amount of seconds in a planet's day and divide it by the rotation speed above:
                    </p>
                    <p>
                    (secPerMin x minPerHour x planetDayLengthHours) / planetRotationSpeed = 36,000
                    </p>
                    <ul>
                        <li>Earth: (60 x 60 x 23.9) = 86,040 / 2.39 = 36,000</li>
                        <li>Jupiter: (60 x 60 x 9.9) = 35,640 / .99 = 36,000</li>
                        <li>Venus: (60 x 60 x 5832.5) = 20,997,00 / 583.25 = 36,000</li>
                    </ul>

                    <h3 className="text--bold">
                        Dev Takeaways
                    </h3>
                    <ul>
                        <li>Animating a background-position from 0% to 200% will allow it to loop infinitely and seamlessly.</li>
                        <li>Mixin interpolation is more capable with CSS custom variables than SCSS variables. I originally used SCSS variables, but I ran into some major roadblocks while interpolating them into the "planetization" mixin. </li>
                        <li>The atmospheric visual effects are a combination of inset and regular box-shadows that provide the dark-side shadow, day-side illumination, and slight atmospheric corona.</li>
                    </ul>

                    <h3 className="text--bold">
                        Fun Planet Info
                    </h3>
                    <ul>
                        <li>Wow Jupiter spins ridiculously fast considering how large it is.</li>
                        <li>Venus spins very slowly AND in the opposite direction of other planets. Seriously, it barely moves in this demo.</li>
                        <li>A day on Mercury is 2/3 of a year on Mercury.</li>
                    </ul>

                    <h3 className="text--bold">
                        Astronomical Notes
                    </h3>
                    <ul>
                        <li>The day and year values in this demo are measured in Earth hours and Earth days.</li>
                        <li>All examples take place during each planet's winter solstice.</li>
                        <li>The Sun is spins at different speeds from its equator to its poles, so this depiction is approximate.</li>
                        <li>Reference: <a href="https://nssdc.gsfc.nasa.gov/planetary/factsheet/" target="_blank">NASA - Planetary Fact Sheet</a></li> 
                    </ul>
                </div>
                <div className="pageblock__demo">
                    <p class="codepen" data-height="700" data-theme-id="36208" data-default-tab="result" data-user="robdimarzo" data-slug-hash="LMOLer" data-pen-title="Solar CSSystem"> <span>See the Pen <a href="https://codepen.io/robdimarzo/pen/LMOLer/"> Solar CSSystem</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>) on <a href="https://codepen.io">CodePen</a>.</span>
</p>
                </div>
            </div>
        </section>
    </main>
  </Layout>
)
export const query = graphql`
  query SolarCSSystemQuery {
    site {
      siteMetadata {
        title
        demoSolarCSSystemTitle
      }
    }
  } 
`
export default Portfolio1
